import { graphql, useStaticQuery } from 'gatsby'
import formatApartment, {
  Apartment,
  MarkdownRemarkApartment,
} from '../../utils/formatters/formatApartment'

const sortApartments = (apartments: Apartment[]) =>
  apartments.sort((a, b) => b.areaInSquareMeters - a.areaInSquareMeters)

const useApartments = () => {
  const {
    apartments: { nodes: apartments },
  } = useStaticQuery(
    graphql`
      query {
        apartments: allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: { eq: "apartment" }
              published: { eq: true }
            }
          }
        ) {
          nodes {
            id
            fields {
              slug
            }
            frontmatter {
              title
              headerImage {
                publicURL
              }
              metaDescription
              areaInSquareMeters
              numberOfBathRooms
              numberOfBeds
            }
          }
        }
      }
    `,
  ) as {
    apartments: {
      nodes: MarkdownRemarkApartment[]
    }
  }

  const formattedApartments = apartments.map(formatApartment)
  const sortedApartments = sortApartments(formattedApartments)

  return sortedApartments
}

export default useApartments
