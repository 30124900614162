import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import { LinkButton } from '../components/Button'

import useApartments from '../hooks/cms/useApartments'
import { Apartment } from '../utils/formatters/formatApartment'

const title = 'Wohnungen | Stadlwirt'

const ApartmentPreview = ({ apartment }: ApartmentPreviewProps) => {
  const {
    title,
    slug,
    headerImage,
    areaInSquareMeters,
    numberOfBathRooms,
    numberOfBeds,
  } = apartment

  return (
    <div className="w-full sm:w-1/2 lg:w-1/3 mb-6">
      <div className="bg-white border sm:mx-4 rounded-lg overflow-hidden h-full">
        <Link to={slug}>
          <img src={headerImage} alt={title} className="w-auto mb-2" />
        </Link>
        <div className="p-6">
          <h2 className="text-lg font-semibold">{title}</h2>
          <div className="mb-3 text-gray-700 text-xs uppercase font-semibold tracking-wide">
            {areaInSquareMeters}m² &bull; {numberOfBeds} Betten &bull;{' '}
            {numberOfBathRooms} Bäder
          </div>
          <LinkButton to={slug}>Zur Wohnung</LinkButton>
        </div>
      </div>
    </div>
  )
}

interface ApartmentPreviewProps {
  apartment: Apartment
}

const ApartmentsPage = () => {
  const apartments = useApartments()

  const { fluidBackgroundImage } = useStaticQuery(
    graphql`
      query {
        fluidBackgroundImage: file(relativePath: { eq: "haus-stadlwirt.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  return (
    <div>
      <SEO title={title} />
      <HeroHeader
        title="Wohnungen"
        fluidBackgroundImage={fluidBackgroundImage}
        staticBackgroundImage="/uploads/haus-stadlwirt.jpg"
      />
      <div className="container">
        <div className="flex flex-wrap my-10">
          {apartments.map((apartment) => {
            return <ApartmentPreview key={apartment.id} apartment={apartment} />
          })}
        </div>
      </div>
    </div>
  )
}

export default ApartmentsPage
